<template>
  <div
    style="position: fixed; top: 0; right: 0; left: 0; z-index: 1312; transition: transform .2s, background .3s;"
    :style="`transform: translateY(${isActive ? top : top + 1000}px); left: 0px`"
    class="sticky-bottom-header q-pa-md row items-center justify-center"
  >
    <div
      v-if="slide === 'default'"
      class="bg-dark bg-animated text-white q-py-sm q-px-md rounded shadow row items-center col"
      style="max-width: 600px;"
    >
      <div class="sticky-bottom-header__content text-subtitle2 col q-pr-sm">
        {{ $t('Caution! You didn\'t save the changes!') }}
      </div>

      <div class="sticky-bottom-header__actions">
        <q-btn
          v-if="!noBack"
          color="black"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Back')"
          no-caps
          unelevated
          @click="back"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          no-caps
          unelevated
          :disable="disabled"
          @click="handleWarning"
        />
      </div>
    </div>

    <div
      v-else
      class="bg-warning bg-animated text-dark q-py-sm q-px-md rounded shadow row items-center col"
      style="max-width: 600px;"
    >
      <div class="sticky-bottom-header__content text-subtitle2 col q-pr-sm">
        {{ $t('Are you sure that you want to save changes?') }}
      </div>

      <div class="sticky-bottom-header__actions">
        <q-btn
          color="black"
          text-color="white"
          class="q-mr-sm"
          :label="$t('No')"
          no-caps
          unelevated
          @click="handleNo"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Yes')"
          no-caps
          unelevated
          @click="handleYes"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Quasar
import { debounce } from 'quasar'

function getDefaultPosition () {
  const height = window.innerWidth > 700
    ? 80
    : 110

  return window.innerHeight - height
}

export default {
  name: 'StickyBottomHeader',
  emits: ['back', 'save'],
  props: {
    noBack: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isActive: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      slide: 'default',
      top: getDefaultPosition()
    }
  },
  mounted () {
    this.handleResize = this.handleResize.bind(this)
    this.handleResize = debounce(this.handleResize, 500)
    window.addEventListener('resize', this.handleResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    back (e) {
      this.$vibrate()
      this.$emit('back', e)
    },
    handleNo () {
      this.slide = 'default'
    },
    handleYes (e) {
      this.slide = 'default'
      this.$emit('save', e)
    },
    handleWarning () {
      this.slide = 'warning'
    },
    handleResize () {
      this.top = getDefaultPosition()
    }
  }
}
</script>
