<template>
  <div
    v-if="isLoading"
    class="row items-center justify-center"
    style="height: 100vh"
  >
    <q-spinner color="light-blue-9" size="3rem" class="q-mr-md" />
  </div>

  <div v-else>
    <div
      v-if="notification"
      :class="`bg-${notification.type} q-pa-md text-h6 text-center`"
    >
      {{ notification.text }}
    </div>

    <form-builder :schema="schema" />

    <div class="row">
      <div class="col-12 col-md-6 q-pa-xs">
        <div>
          <q-input
            :model-value="search"
            type="text"
            color="white"
            standout="bg-teal text-white"
            :label="$t('Search')"
            class="text-white q-pb-sm"
            label-color="light"
            :debounce="300"
            :disable="!rate || !locality"
            @update:model-value="handleSearch"
          />
        </div>

        <div v-if="notFormal">
          <div class="test-subtitle1 q-px-lg q-py-xs text-yellow-9">
            {{ $t("Recipient address") }}
          </div>

          <div class="text-subtitle1 q-mb-sm q-px-md rounded">
            <q-chip
              v-for="(word, i) in words"
              :key="`${word}:${i}`"
              :class="getChipsClass(word)"
              class="q-mr-sm"
              clickable
              square
              @click="handleWord(word)"
            >
              {{ word }}
            </q-chip>
          </div>
        </div>

        <div
          v-if="servicePoints.length > 0 && !servicePointsLoading"
          class="q-px-xs q-pb-md"
        >
          <q-virtual-scroll
            ref="list"
            style="
              border: 1px solid rgba(0, 0, 0, 0.12);
              height: calc(100vh - 120px);
            "
            :items="servicePoints"
            separator
          >
            <template v-slot="{ item }">
              <q-item
                :key="item.id"
                :class="
                  !!item && servicePoint && servicePoint.id === item.id
                    ? 'bg-teal-3'
                    : ''
                "
                dense
                clickable
                @click="handleMarkerSelect(item)"
              >
                <q-item-section thumbnail class="q-px-md">
                  <div
                    v-if="
                      item._embedded.deliveryService &&
                      item._embedded.deliveryService.logo
                    "
                  >
                    <q-img
                      :src="`${$appOptions.defaultServer}${item._embedded.deliveryService.logo}`"
                      style="width: 70px; height: 100%"
                      :contain="true"
                    />
                  </div>

                  <div v-else-if="item._embedded.deliveryService">
                    {{ item._embedded.deliveryService.name }}
                  </div>
                </q-item-section>

                <q-item-section>
                  <h5 class="q-my-none text-subtitle1">
                    {{ item.name }}
                  </h5>

                  <p class="text-caption q-my-none">
                    {{ item.rawAddress }}
                  </p>

                  <small>{{ item.rawTimetable }}</small>
                </q-item-section>

                <q-item-section style="max-width: 80px" class="q-mr-sm">
                  <q-btn
                    color="light-blue-9"
                    text-color="white"
                    size="sm"
                    :label="$t('Choose')"
                    no-caps
                    unelevated
                    :disable="
                      !!item && servicePoint && servicePoint.id === item.id
                    "
                    @click="handleSubmit(item)"
                  />
                </q-item-section>
              </q-item>
            </template>
          </q-virtual-scroll>
        </div>

        <div
          v-else-if="servicePointsLoading"
          class="row items-center justify-center full-height"
          style="min-height: 200px"
        >
          <q-spinner color="light-blue-9" size="3rem" class="q-mr-md" />
        </div>

        <div
          v-else
          class="row fit items-center justify-center"
          style="min-height: 200px"
        >
          {{ $t("No pick up points was found!") }}
        </div>
      </div>

      <div class="col-12 col-md-6 q-pa-xs">
        <l-map
          ref="map"
          style="min-height: calc(100vh - 60px)"
          :zoom="zoom"
          :center="center"
          :min-zoom="3"
          :max-zoom="20"
        >
          <l-tile-layer :url="url" :attribution="attribution" />

          <l-layer-group ref="features">
            <l-popup>
              <div class="custom-popup">
                <div class="row full-width justify-between">
                  <h6 class="q-mx-auto q-my-none">
                    {{ currentPoint.name }}
                  </h6>
                </div>

                <p class="text-caption">
                  {{ currentPoint.rawAddress }}
                </p>

                <small class="q-my-xs block">
                  {{ currentPoint.rawTimetable }}
                </small>

                <q-btn
                  color="light-blue-9"
                  text-color="white"
                  class="full-width"
                  size="sm"
                  :label="$t('Choose')"
                  no-caps
                  unelevated
                  :disable="
                    !!servicePoint && servicePoint.id === currentPoint.id
                  "
                  @click="handleSubmit(currentPoint)"
                />
              </div>
            </l-popup>
          </l-layer-group>

          <!-- <marker-cluster :options="{}"> -->
          <l-marker
            v-for="point of servicePointsWithGeo"
            :key="point.id"
            :lat-lng="createGeo(point.geo)"
            @click="handleMarkerSelect(point)"
          >
            <l-icon
              :icon-size="getMarkerSize(createGeo(point.geo))"
              :icon-url="createIcon(point)"
            />
          </l-marker>
          <!-- </marker-cluster> -->
        </l-map>
      </div>
    </div>

    <sticky-bottom-header :is-active="hasChange" no-back @save="save" />
  </div>
</template>

<script>
// Components
import { LMap, LTileLayer, LMarker, LPopup, LIcon, LLayerGroup } from '@vue-leaflet/vue-leaflet/src/lib'

// Configs
import PinsConfig from '../../config/PinsConfig'
import StickyBottomHeader from './components/header/StickyBottomHeader.vue'

/**
 * Create Geo
 *
 * @param {Any} value
 * @param {Any} defaultCoords
 *
 * @returns {Array}
 */
function createGeo (value, defaultCoords = window.appOptions.defaultCoords || [43.204666, 27.910543]) {
  if (!value) {
    console.error('Geo is empty!')
    return Array.isArray(defaultCoords)
      ? defaultCoords
      : createGeo(defaultCoords)
  }

  if (Array.isArray(value)) {
    return value
  }

  if (typeof value === 'object') {
    return [value.latitude, value.longitude]
  }

  if (value[0] === '{' && value[value.length - 1] === '}') {
    return createGeo(JSON.parse(value))
  }

  const indexOfOpen = value.indexOf('(') + 1
  const indexOfClose = value.indexOf(')')

  return value.slice(indexOfOpen, indexOfClose).split(' ').map(Number).reverse()
}

export default {
  name: 'App',
  components: {
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-popup': LPopup,
    'l-icon': LIcon,
    'l-layer-group': LLayerGroup,
    // MarkerCluster,
    StickyBottomHeader
  },
  data () {
    return {
      hasChange: false,
      pinTypes: PinsConfig,
      search: '',
      deliveryRequest: null,
      servicePoint: null,
      rate: null,
      locality: null,
      rates: [],
      localities: [],
      servicePoints: [],
      servicePointsLoading: false,
      notFormal: '',
      currentPoint: {},
      zoom: 13,
      center: [43.204666, 27.910543],
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: [43.204666, 27.910543],
      logo: null,
      notification: null,
      isLoading: false,
    }
  },
  computed: {
    servicePointsWithGeo () {
      return this.servicePoints.filter(x => {
        return x.geo && x.geo.length > 0
      })
    },
    words () {
      return this.notFormal.split(' ').map(x => x.replace(',', ''))
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.postcode,
                required: true,
                label: this.$t('Post/Zip code'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-sm',
                debounce: 300,
                onInput: value => {
                  this.postcode = value
                  this.loadLocalitiesByPostcode(value)
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                value: this.locality,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-sm',
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '') + (row._embedded && row._embedded.area.name ? ' (' + row._embedded.area.name + ')' : '')
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (locality) => {
                  this.locality = locality
                  this.handleValueSubmit()
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                value: this.rate,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-sm',
                required: true,
                imagePreview: (row) => {
                  if (!row || !row._embedded || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.$appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.rate.getAll(query)
                },
                onChange: (rate) => {
                  this.rate = rate
                  this.handleValueSubmit()
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (!this.$route.query.id && !this.$route.query.extId) {
      this.addErrorNotification(this.$t('No shipment'))
    } else {
      this.isLoading = true
      this.loadDeliveryRequest()
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  updated () {
    if (this.$refs.map && this.$refs.map.mapObject) {
      this.$refs.map.mapObject._onResize()
    }
  },
  methods: {
    save () {

      const data = {
        recipientLocality: this.getID(this.locality),
        rate: this.getID(this.rate),
        servicePoint: this.getID(this.servicePoint)
      }

      this.isLoading = true

      return this.$service.deliveryRequest.save(data, this.deliveryRequest)
        .then(data => {
          this.hasChange = false
          this.addNotification(`Successful saved!`)
          return data
        })
        .catch(err => {
          this.addErrorNotification(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getID (data) {
      return (data && data.id) || null
    },
    loadDeliveryRequest () {
      if (this.$route.query.id) {
        return this.$service.deliveryRequest.get(this.$route.query.id)
          .then(item => {
            this.setData(item)
            return item
          })
          .catch(err => {
            this.addErrorNotification(err.message)
          })
      }

      const query = {
        per_page: 1,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: this.$route.query.extId }
        ]
      }

      return this.$service.deliveryRequest.getAll(query)
        .then(({ items, totalItems }) => {
          if (totalItems <= 0) {
            return Promise.reject(new Error(`Shipment with extId ${this.$route.query.extId} is not found!`))
          }

          if (totalItems > 1) {
            return Promise.reject(new Error(`More than one shipment is found with extId ${this.$route.query.extId}!`))
          }

          this.setData(items[0])
          return items[0]
        })
        .catch(err => {
          this.addErrorNotification(err.message)
        })
    },
    setData (item) {
      this.deliveryRequest = item.id
      this.locality = item._embedded.recipientLocality
      this.postcode = item._embedded.recipientAddress.postcode
      this.servicePoint = item._embedded.servicePoint
      this.rate = item._embedded.rate
      this.handleValueSubmit()
    },
    handleSubmit (point) {
      this.servicePoint = point
      this.hasChange = true
      const index = this.servicePoints.findIndex(({ id }) => id === point.id)
      this.$refs.list.scrollTo(index, 'start')
    },
    handleMarkerSelect (point) {
      this.currentPoint = point
      const coords = this.createGeo(point.geo)
      this.marker = coords
      this.center = coords

      setTimeout(() => {
        if (!this.$refs.map) {
          return
        }

        this.$refs.map.mapObject.setView(coords, 16)
        this.openPopUp(coords)
      })
    },
    addNotification (text) {
      this.notification = {
        type: 'positive',
        text
      }
    },
    addErrorNotification (text) {
      this.notification = {
        type: 'negative',
        text
      }
    },
    loadLocalitiesByPostcode (value) {
      if (value.length < 4) {
        return
      }

      const query = {
        page: 1,
        per_page: 1,
        filter: [
          { type: 'eq', field: 'extId', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addErrorNotification('No localities found!')
            return
          }

          this.locality = items[0]._embedded.locality
          this.handleValueSubmit()
        })
    },
    handleValueSubmit () {
      if (this.rate && this.locality) {
        return this.loadServicePoints()
      }
    },
    loadServicePoints () {
      const query = {
        per_page: 250,
        page: 1,
        search: this.search,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'locality', value: this.locality.id },
          this.createDeliveryServiceFilter(this.rate._embedded.deliveryService)
        ]
      }

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        const fix = query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'

        query.search = query.search.trim() + fix
      } else if (query.search.includes('%')) {
        query.search = query.search.trim()
      }

      this.servicePointsLoading = true

      return this.$service.servicePoint.getAll(query)
        .then(({ items }) => {
          this.servicePoints = items
        })
        .finally(() => {
          this.servicePointsLoading = false
        })
    },
    createDeliveryServiceFilter (deliveryService) {
      if (deliveryService.id) {
        return { type: 'eq', field: 'deliveryService', value: deliveryService.id }
      }

      const value = this.extractIDFromLink(deliveryService)

      return { type: 'eq', field: 'deliveryService', value }
    },
    createIcon (point) {
      const pin = this.pinTypes[(point._embedded.deliveryService || {}).name] || this.pinTypes.default

      return this.isChoosed(this.createGeo(point.geo))
        ? this.pinTypes.selectedPin
        : pin
    },
    extractIDFromLink (deliveryService) {
      const link = deliveryService._links.self.href
      const index = link.lastIndexOf('/')
      return link.slice(index + 1)
    },
    updateMapSize () {
      if (this.$refs.map) {
        this.$refs.map.mapObject.invalidateSize()
      }
    },
    openPopUp (latLng) {
      if (this.$refs.features) {
        this.$refs.features.mapObject.openPopup(latLng)
      }
    },
    createGeo (value = '') {
      return createGeo(value, (this.locality || {}).geo)
    },
    getMarkerSize (coords) {
      return this.isChoosed(coords)
        ? [34, 50]
        : [26, 40]
    },
    isChoosed (coords) {
      return !!this.marker && coords[0] === this.marker[0] && coords[1] === this.marker[1]
    },
    handleSearch (search) {
      this.search = search
      this.handleValueSubmit()
    },
    handleWord (word) {
      this.handleSearch(word)
    },
    getChipsClass (word) {
      if (word === this.search) {
        return 'bg-dark text-white'
      }

      return this.$q.dark.isActive
        ? 'bg-grey-9'
        : ''
    }
  }
}
</script>
