import { drService } from './delivery-request.service'
import { deliveryServicesService } from './delivery-services.service'
import { localitiesService } from './locality.service'
import { orderService } from './order.service'
import { postcodeService } from './postcodes.service'
import { dsRatesService } from './rates.service'
import { senderService } from './senders.service'
import { servicePointService } from './service-points.service'

export default {
  order: orderService,
  sender: senderService,
  deliveryRequest: drService,
  deliveryService: deliveryServicesService,
  locality: localitiesService,
  postcode: postcodeService,
  rate: dsRatesService,
  servicePoint: servicePointService
}
